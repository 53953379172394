/* eslint-disable @nx/enforce-module-boundaries */
import type { ReactNode } from 'react';
import React, { useState } from 'react';
import cx from 'classnames';
import Button from '@clearscore/ui.rainbow.drivescore.button';
import StatusWarningIcon from '@clearscore/rainbow.icons.status-warning';
import StatusSuccessIcon from '@clearscore/rainbow.icons.status-success';
import { Field, Form } from 'react-final-form';
import useLanguage from '@clearscore-group/lib.hooks.use-language';
import csMutateQuery from '@clearscore-group/lib.hooks.use-cs-mutate-query';
import template from 'lodash.template';
import { useMutation } from '@tanstack/react-query';
import { isRequiredValidation } from '@clearscore-group/lib.validation.is-required';
import { isEmailValidation } from '@clearscore-group/lib.validation.is-email';
import interpolateWithComponents from '@clearscore-group/lib.helpers.interpolate-with-components';
import axios from 'axios';
import Botpoison from '@botpoison/browser';
import type { FormApi } from 'final-form';
import 'regenerator-runtime/runtime';

import styles from './partner-form.module.css';
import lang from './lib/lang';
import { NAME, COMPANY, EMAIL, MESSAGE, FORM_SPARK_URL, BOTPOISON_PUBLIC_KEY } from './lib/constants';

const botpoison = new Botpoison({
    publicKey: BOTPOISON_PUBLIC_KEY,
});

interface FormValues {
    name: string;
    email: string;
    company: string;
    message: string;
}

type TextInputProps = {
    errorMessage?: string;
    label: string;
    id: string;
    isInvalid?: boolean;
    Tag?: React.ElementType;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextInput = ({ errorMessage = '', label, id, isInvalid = false, Tag = 'input', ...rest }: TextInputProps) => (
    <div className={styles.inputWrapper} data-id={id}>
        <label className={styles.inputLabel} htmlFor={id}>
            {label}
        </label>
        <Tag
            className={cx(styles.input, { [styles.isInvalid]: isInvalid, [styles.textArea]: Tag === 'textarea' })}
            id={id}
            {...rest}
        />
        {isInvalid ? (
            <React.Fragment>
                <div className={styles.errorMessage}>{errorMessage}</div>
                <StatusWarningIcon className={styles.errorIcon} role="img" />
            </React.Fragment>
        ) : null}
    </div>
);

const BoldUserEmail = ({ children }: { children: ReactNode }) => <span className={styles.userEmail}>{children}</span>;

const PartnerForm = () => {
    const [userEmail, setUserEmail] = useState('');
    const { formHeader, name, email, company, message, submitError, submitSuccess, submitButtonCta } =
        useLanguage(lang);

    const { mutate, isLoading, isSuccess, isError } = useMutation([FORM_SPARK_URL], async (data: object) => {
        const { solution } = await botpoison.challenge();
        return csMutateQuery({
            client: axios.create({ baseURL: FORM_SPARK_URL, responseType: 'json' }),
            method: 'post',
            data: { ...data, _botpoison: solution },
        });
    });

    const handleFormSubmit = (values: FormValues, form: FormApi<FormValues>) => {
        mutate(values, {
            onSuccess: () => {
                setUserEmail(values.email);
                form.restart();
            },
        });
    };

    return (
        <div className={styles.partnerFormCard}>
            <h3 className={styles.formHeader}>{formHeader}</h3>
            <Form
                onSubmit={handleFormSubmit}
                render={({ handleSubmit }) => (
                    <form className={styles.formWrapper} onSubmit={handleSubmit}>
                        <Field name={NAME} validate={isRequiredValidation(name.errorMessage)}>
                            {({ input, meta }) => (
                                <TextInput
                                    {...input}
                                    errorMessage={meta.error}
                                    id={NAME}
                                    isInvalid={meta.touched && !meta.active && meta.invalid}
                                    label={name.label}
                                    placeholder={name.placeholder}
                                />
                            )}
                        </Field>
                        <div className={styles.displayDirection}>
                            <Field name={EMAIL} validate={isEmailValidation(email.errorMessage)}>
                                {({ input, meta }) => (
                                    <TextInput
                                        {...input}
                                        errorMessage={meta.error}
                                        id={EMAIL}
                                        isInvalid={meta.touched && !meta.active && meta.invalid}
                                        label={email.label}
                                        placeholder={email.placeholder}
                                    />
                                )}
                            </Field>
                            <Field name={COMPANY} validate={isRequiredValidation(company.errorMessage)}>
                                {({ input, meta }) => (
                                    <TextInput
                                        {...input}
                                        errorMessage={meta.error}
                                        id={COMPANY}
                                        isInvalid={meta.touched && !meta.active && meta.invalid}
                                        label={company.label}
                                        placeholder={company.placeholder}
                                    />
                                )}
                            </Field>
                        </div>
                        <Field name={MESSAGE}>
                            {({ input }) => (
                                <TextInput
                                    {...input}
                                    id={MESSAGE}
                                    label={message.label}
                                    placeholder={message.placeholder}
                                    Tag="textarea"
                                />
                            )}
                        </Field>
                        <div className={styles.buttonOutline}>
                            <Button htmlType={Button.HtmlType.SUBMIT} isFullWidth isLoading={isLoading}>
                                {submitButtonCta}
                            </Button>
                        </div>
                        {isSuccess ? (
                            <div className={styles.submitMessage} data-id="submit-message">
                                <StatusSuccessIcon className={cx(styles.submitIcon, styles.success)} role="img" />
                                <span className={styles.lineHeight}>
                                    {interpolateWithComponents(template(submitSuccess)({ userEmail }), [
                                        {
                                            Component: BoldUserEmail,
                                        },
                                    ])}
                                </span>
                            </div>
                        ) : null}
                        {isError ? (
                            <div className={cx(styles.submitMessage, styles.error)}>
                                <StatusWarningIcon className={cx(styles.submitIcon, styles.error)} role="img" />
                                {submitError}
                            </div>
                        ) : null}
                    </form>
                )}
            />
        </div>
    );
};
export default PartnerForm;
